const manifesto = `
<s>Look at us! We are not out of breath, our hearts are not in the least tired. For they are nourished by fire, light and speed! Haters do not even remember the feeling of being alive. Standing on the world's summit we launch to challenge the stars!</s><br><br>

<s>WE will be engaged in militarism and patriotism. War is the only cure for the world. WE believe in the destructive gesture of the anarchists - the beautiful ideas which kill. WE will offer our services to NATO in the Battle of Berlin II (WWIII).</s><br><br>

<s>Destroy the Nations. England is dead! Destroy America.</s> AAAARRRRRRRRGGHHHHHHHH <s>on Canada! Art for Art's Sake : NFTs for NFT's Sake.</s> AAAARRRRRRRRGGHHHHHHHHhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh. ARGHHHHRRHHHHHHHH.<br><br>

<s>Art for art for art for art for art, yes?</s><br><br>

Paul Tristis says subject-object blurring.<br><br>

Only the fool never changes his mind<br>
Art on the internet for the first time<br>
All ideas should be realized at one point or another to see what you can do with them, how you can work your way out of them, and how you can live with the results.<br><br>

The desire to create continually is <s>vulgar</s> and betrays jealousy, envy, ambition. If one is something one really does not need to make anything --and one nonetheless does very much. There exists above the ''productive'' man a yet <s>higher species.</s><br><br>


Some say that Avant Nfts are simply art, <s>however they are not art at all</s>,  <s>they are the … towards pure</s> speculation <s>which</s> has always been at the heart of taste.<br>
<s>We will inaugurate what will historically be the last of the crafts. The role of amateur-professional dilettante— of anti-specialist — is again a specialization up to the point of economic and mental abundance, when everyone</s> becomes autistic <s>in the sense that the artists have not attained the construction of their own life.</s><br><br>


What are the organizational perspectives of life online which <s>authentically</s> "reorganizes production on the basis of the <s>free and equal</s> association of the producers"?🤔🤔 Work would more and more be reduced as an exterior necessity through the automation of production and the socialization of vital goods, which would finally give complete liberty to the individual.🤔🤔 Thus liberated from all economic responsibility,🤔 liberated from all the debts and responsibilities from the past and other people,🤔 humankind will exude a new (?) surplus value, incalculable in money because it would be impossible 🤔 to reduce it to the measure of waged work. The guarantee of the liberty of each and of all is in the value of the game, of life freely(?) constructed. The exercise of this ludic recreation is the framework of the only guaranteed equality with non-exploitation (???) of man by man. The liberation🤔 of the game, its creative autonomy, <s>supersedes</s> <i>recapitulates</i> the <s>ancient</s>  <i>present</i> <s>division</s> <i>relationship</i> between imposed work and passive leisure.<br><br>

7.<br><br>

<a href="https://www.youtube.com/watch?v=5ZDUEilS5M4" target="_blank">https://www.youtube.com/watch?v=5ZDUEilS5M4</a><br><br>

So what really is the Avant NFT? It's the realization of a <s>better</s> game, which more exactly is provoked by the human non presence🤔. The revolutionary gamesters (👊) of all countries can be united (?) in the Avant NFT to commence the emergence from the prehistory of daily life🤔🤔.<br><br>

There is a statement that cannot be disputed🤔: namely, that one can be(?) avant(?) without ever having produced or purchased.  The prerequisite, however, is a more or less intensely experienced desire to act <s>avant</s>. Some logical gesture carried out in this spirit can be raised to an act of extraordinary beauty, can be raised even to the level of the art itself. The avant(?) act then is a pose in its nobelist (🏆) form, a pose free of all vanity and animated with high spirited modesty.<br><br>

Incessant documentation may be the self-breeding curse of this century but unless you go for the mute pout and glare of purity 😒<br>
 any course of action that contains within it absolute uniqueness, utter extremity and attendant magnitude is done to attract, if not provoke, <s>reaction</s><br><br>

Hope and Memory have <s>one</s> son and his name is Spiky, and he has built his dwelling far from the desperate field where men hang out their garments upon forked boughs to be banners of battle.  O beloved son of Hope and Memory, be with me for a little 🤔
`;

export default manifesto; 