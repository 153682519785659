import React from 'react';

import logo from './logo.svg';
import './fonts.css'
import './App.css';

import { useState, useEffect } from 'react';

import { Contract, providers } from 'ethers';

import mix from "./audio/spiky_dj_live_mix.mp3";

import erreh1 from "./audio/scratchy/erreh_1.mp3";
import erreh3 from "./audio/scratchy/erreh_3.mp3";

import djTag1 from "./audio/scratchy/spiky_dj_tag_1.mp3";
import djTag2 from "./audio/scratchy/spiky_dj_tag_2.mp3";
import djTag3 from "./audio/scratchy/spiky_dj_tag_3.mp3";
import djTag4 from "./audio/scratchy/spiky_dj_tag_4.mp3";

import contractABI from './abi.json';

import manifesto from './manifesto.js';

import Web3Modal from 'web3modal';

import WalletConnectProvider from '@walletconnect/web3-provider';

import truncateEthAddress from 'truncate-eth-address';

const contractAddress = "0x115F8D0595A04999cBdFdcA9Cd46C7ccCA7D2308";
const targetChainName = "MAINNET";
const targetChain = 1;
const wrongNetworkMessage = "WRONG NETWORK. SWITCH TO " + targetChainName;
const connectWalletMessage = "CONNECT WALLET TO MINT";

function App() {

  const [erreh1Audio, setErreh1] = useState(null);
  const [erreh3Audio, setErreh3] = useState(null);

  const [djTag1Audio, setDJTag1Audio] = useState(null);
  const [djTag2Audio, setDJTag2Audio] = useState(null);
  const [djTag3Audio, setDJTag3Audio] = useState(null);
  const [djTag4Audio, setDJTag4Audio] = useState(null);

  const [showManifesto, setShowManifesto] = useState(false);

  const [gotNumMinted, setGotNumMinted] = useState(false);
  const [numMinted, setNumMinted] = useState(0);

  const [minting1, setMinting1] = useState(false);
  const [minting5, setMinting5] = useState(false);
  const [minting15, setMinting15] = useState(false);
  const [minting30, setMinting30] = useState(false);

  const [audioMix, setAudioMix] = useState(null);
  const [mixPlaying, setMixPlaying] = useState(false);
  const [mixPaused, setMixPaused] = useState(false);
  const [mixStopped, setMixStopped] = useState(false);

  const [fastForward, setFastForward] = useState(false);
  const [trackBack, setTrackBack] = useState(false);

  //web3 
  const [consoleText, setConsoleText] = useState(connectWalletMessage);

  const [web3Modal, setWeb3Modal] = useState(null);
  const [address, setAddress] = useState("");
  const [humanAddress, setHumanAddress] = useState("");
  const [connected, setConnected] = useState(false);

  const [ethersProvider, setEthersProvider] = useState(null);
  const [web3ModalProvider, setWeb3ModalProvider] = useState(null);

  useEffect(() => {
    let audio = new Audio(mix);
    setAudioMix(audio);

    let _erreh1 = new Audio(erreh1);
    setErreh1(_erreh1);

    let _erreh3 = new Audio(erreh3);
    setErreh3(_erreh3);

    let _djTag1 = new Audio(djTag1);
    setDJTag1Audio(_djTag1);

    let _djTag2 = new Audio(djTag2);
    setDJTag2Audio(_djTag2);

    let _djTag3 = new Audio(djTag3);
    setDJTag3Audio(_djTag3);

    let _djTag4 = new Audio(djTag4);
    setDJTag4Audio(_djTag4);

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "4ebebc5a34f44308ab4df40a00f21d34",
        }
      }  
    };

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    setWeb3Modal(web3Modal);
  }, []);

  useEffect(() => {
    if(web3Modal && web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [web3Modal]);

  useEffect(() => {
    if(web3ModalProvider) {
      web3ModalProvider.on("accountsChanged", (accounts) => {
        console.log(accounts);

        if(accounts.length > 0) {
          connectWallet();
        }

        else {
          setConnected(false);
          setConsoleText(connectWalletMessage);
        }
      });

      web3ModalProvider.on("chainChanged", (chainId) => {
        console.log("chain changed, id is " + chainId);
        setConnected(false);

        if(connected) {
          setConsoleText(wrongNetworkMessage);
        }
        
        connectWallet();
      });    
    }
  }, [web3ModalProvider, connected]);

  useEffect(() => {
    if(connected) {
      setConsoleText("READY TO MINT TO " + humanAddress);

      //get num minted
      async function getNumMinted() {
        if(ethersProvider) {
          try {  
            console.log("getting num minted");
            let signer = ethersProvider.getSigner();
      
            const contract = new Contract(contractAddress, contractABI, signer);
      
            const numMinted = await contract.totalSupply();
  
            console.log("num minted " + numMinted);
            console.log("setting num minted");
            setNumMinted(parseInt(numMinted));
            setGotNumMinted(true);
          }
          
          catch(err) {
            console.log(err);
          }
        }  
      }

      getNumMinted();
    }
  }, [humanAddress, connected, ethersProvider]);

  async function connectWallet() {
    const provider = await web3Modal.connect();

    setWeb3ModalProvider(provider);

    const ethersProvider = new providers.Web3Provider(provider);

    const chainId = await ethersProvider.getNetwork().then(network => network.chainId);
    console.log("chain id is " + chainId);

    if(chainId !== targetChain) {
      setConnected(false);
      setConsoleText(wrongNetworkMessage);
      return;
    }
    
    setEthersProvider(ethersProvider);

    console.log("getting user address");

    const userAddress = await ethersProvider.getSigner().getAddress();

    const name = await ethersProvider.lookupAddress(userAddress);

    setAddress(userAddress);

    if(name) {
      setHumanAddress(name.toUpperCase());
    }

    else {
      let truncatedAddress = truncateEthAddress(userAddress);
      setHumanAddress(truncatedAddress);
    }

    setConnected(true);

  }

  function playMintAudio(quantity) {
    switch(quantity) {
      case 1:
        if(djTag1Audio != null) djTag1Audio.play();
        break;
      case 5:
        if(djTag2Audio != null) djTag2Audio.play();
        break;
      case 15:
        if(djTag3Audio != null) djTag3Audio.play();
        break;
        case 30:
        if(djTag4Audio != null) djTag4Audio.play();
        break;
    }
  }

  function switchMintingButton(setOn, quantity) {
    switch(quantity) {
      case 1:
        setMinting1(setOn);
        break;
      case 5:
        setMinting5(setOn);
        break;
      case 15:
        setMinting15(setOn);
        break;
      case 30:
        setMinting30(setOn);
        break;
    }
  }

  async function mintSpiky(quantity) {
    if(!connected) return;

    try {
      playMintAudio(quantity);
      switchMintingButton(true, quantity);

      console.log('minting ' + quantity + ' Spiky DJs');

      setConsoleText("CONFIRM TRANSACTION TO MINT");

      let signer = ethersProvider.getSigner();

      const contract = new Contract(contractAddress, contractABI, signer);

      const price = await contract.checkPrice(quantity);

      const tx = await contract.mint(quantity, {
        value: price
      });

      setConsoleText("MINTING...");

      await tx.wait();

      switchMintingButton(false, quantity);
      setConsoleText("MINTED " + quantity.toString() + " SPIKY DJS");

      setNumMinted(numMinted + quantity);
    }
    
    catch(err) {
      console.log(err);
      setConsoleText("READY TO MINT TO " + humanAddress);
      switchMintingButton(false, quantity);
    }
  }

  function turnOffAllPlayerButtons() {
    setMixPlaying(false);
    setMixPaused(false);
    setMixStopped(false);
    setFastForward(false);
    setTrackBack(false);
  }


  function playMix() {
    if(audioMix != null) {
      audioMix.play();  
      turnOffAllPlayerButtons();
      setMixPlaying(true);
    }
  }

  function pauseMix() {
    if(audioMix != null) {
      audioMix.pause();
      turnOffAllPlayerButtons();
      setMixPaused(true);
    }
  }

  function stopMix() {
    if(audioMix != null) {
      audioMix.pause();
      audioMix.currentTime = 0;
      turnOffAllPlayerButtons();
      setMixStopped(true);
    }
  }

  function skipForward() {
    if(erreh1Audio != null) {
      erreh1Audio.play();
      audioMix.pause();
      turnOffAllPlayerButtons();
      setFastForward(true);
    }
  }

  function skipBack() {
    if(erreh3Audio != null) {
      erreh3Audio.play();
      audioMix.pause();
      turnOffAllPlayerButtons();
      setTrackBack(true);
    }
  }
  
  function restartTextAnim() {
    document.getAnimations().forEach((anim) => {
      anim.cancel();
      anim.play();
    });  
  }

  function toggleManifesto() {
    let _showManifesto = !showManifesto;
    setShowManifesto(_showManifesto);
    restartTextAnim();
  }

  return (
    <div className="App">

    <div className="console"></div>

    <button className={mixPlaying ? "play-btn playing" : "play-btn"} onClick={() => playMix()}></button>
    <button className={mixPaused ? "pause-btn paused" : "pause-btn"} onClick={() => pauseMix()}></button>
    <button className={mixStopped ? "stop-btn stopped" : "stop-btn"} onClick={() => stopMix()}></button>

    <button className={fastForward ? "skip-fwd-btn skipped-fwd" : "skip-fwd-btn"} onClick={() => skipForward()}></button>
    <button className={trackBack ? "skip-back-btn skipped-back" : "skip-back-btn"} onClick={() => skipBack()}></button>

    <button className={connected ? "connect-btn connected" : "connect-btn"} onClick={() => connectWallet()}></button>

    <button className={minting1 ? "mint-1-btn minted-1" : "mint-1-btn"} onClick={() => mintSpiky(1)}></button>
    <button className={minting5 ? "mint-5-btn minted-5" : "mint-5-btn"} onClick={() => mintSpiky(5)}></button>
    <button className={minting15 ? "mint-15-btn minted-15" : "mint-15-btn"} onClick={() => mintSpiky(15)}></button>
    <button className={minting30 ? "mint-30-btn minted-30" : "mint-30-btn"} onClick={() => mintSpiky(30)}></button>

    <button className="manifesto-btn" onClick={() => toggleManifesto()}></button>

    <div className="console-text">{consoleText}</div>


    <a href="https://twitter.com/spiky_dj_" target="_blank" rel="noreferrer">
    <button className="twitter-link"></button>
    </a>

    <div className="spiky-dj-text"></div>
    <div className="spiky-dj-image"></div>  

    {showManifesto && <div className="manifesto-container">
        <div className="manifesto-text" dangerouslySetInnerHTML={{__html: manifesto}}></div>
    </div>}

    {gotNumMinted && <div className="num-minted"> MINTED: {numMinted}/3309</div>}

    <div className="mint-1-txt"></div>
    <div className="mint-5-txt"></div>
    <div className="mint-15-txt"></div>
    <div className="mint-30-txt"></div>

    <div className="minting-zone"></div>

    </div>
  );
}

export default App;
